import { unauthenticatedClient } from "~/utils/axiosClient.util";
import { CookieKeyEnum } from "~/enums";

export async function authCustomer(order_identifier: string, dob: string) {
  await unauthenticatedClient
    .post<any>("/auth", {
      order_identifier,
      dob,
    })
    .then((res) => {
      useCookie(CookieKeyEnum.TOKEN).value = res.token;
    });
}
