<template>
  <div class="relative h-full">
    <div class="mx-auto max-w-7xl h-full">
      <div class="relative z-10 lg:w-full h-full">
        <div class="relative px-6 lg:px-8">
          <div class="flex flex-col justify-center items-center">
            <div class="px-6 py-16 sm:px-6 sm:py-8 lg:px-8">
              <div class="mx-auto max-w-2xl text-center">
                <h2
                  class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                >
                  Healthcare - centered around you
                </h2>
                <p
                  class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600"
                >
                  Welcome to the Healistic order portal. Please provide your
                  order ID and date of birth, to access your order.
                </p>
              </div>
            </div>
            <div class="sm:min-w-[520px] min-w-full">
              <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <form class="flex flex-col gap-5">
                  <div>
                    <label
                      for="script-id"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Order ID</label
                    >
                    <div class="mt-2">
                      <input
                        id="script-id"
                        v-model="orderNumber"
                        name="script-id"
                        type="text"
                        required
                        placeholder="GP000123"
                        class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-viola-400 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for="patient-dob"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Recipient's date of birth</label
                    >
                    <div class="mt-2 max-w-[424px]">
                      <InputDob v-model="dob" />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="flex w-full justify-center rounded-md bg-viola-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-viola-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-viola-400"
                      @click.prevent="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { authCustomer } from "~/api/auth.api";
import { generateOrderNumber } from "~/composables/orderNumber.generator";
definePageMeta({
  auth: false,
});
const queryParam = useRoute().query.order_id as string;

const orderNumber = ref("");
const dob = ref("");

onBeforeMount(() => {
  if (queryParam) {
    const orderId = generateOrderNumber(
      Number(queryParam.split("-")[1]),
      queryParam.split("-")[0] as "app" | "gp",
    );
    if (parseOrderNumber(orderId)) {
      orderNumber.value = orderId;
    }
  }
});
const submit = () => {
  const parsedOrderId = parseOrderNumber(orderNumber.value);
  if (!parsedOrderId) return;
  authCustomer(orderNumber.value, dob.value).then(() => {
    navigateTo(`/${parsedOrderId}`);
  });
};
</script>
